import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { fpqUrl } from '../utils/variables';

const NotFoundPage = () => (
	<Layout>
		<Seo title="Page not found" />
		<div className="o-wrapper">
			<div className="o-row-4">
				<h1 className="c-404-headline">404 - The page cannot be found</h1>
			</div>
			<div className="c-404-content">
				<div className="o-row-3">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/riley-404.jpg"
						alt="woman smiling"
						loading="lazy"
					/>
				</div>
				<div className="o-row-4p5">
					<div className="c-404-box">
						<div className="o-row-3">
							<p className="c-404-copy">
								Do you need a hearing aid consultation? Click <a href={fpqUrl}>here</a> and one of our hearing aid
								experts will contact you - <strong>for free and without obligation.</strong>
							</p>
						</div>
						<ul className="c-404-usps">
							<li>Competent consultation by hearing aid experts</li>
							<li>Hearing aids from all leading manufacturers for competitive prices</li>
							<li>Find out which hearing aid fits you best</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
